.character-download__container {
  border-radius: 25px;
  margin: 150px 0;
  @include breakpoint(small only) {
    margin: 300px 0 20px;
  }
  padding: 10px;
  text-align: center;
  .character-download {
    display: grid;
    grid-template: 30px auto auto / 1fr;
    justify-content: center;
    justify-items: center;
    @include breakpoint(medium) {

      grid-template-columns: auto 100px 1fr;
      grid-template-rows: 100px;
      grid-column-gap: 10px;
      align-items: center;
    }
  }
  .download__preview {
    height: 300px;
    @include breakpoint(small only) {
      margin-top: -275px;
    }

  }
  p {
    color: $white;
    font-family: $header-font-family;
    display: inline-block;
    font-size: rem-calc(22);
    line-height: 1.1;
  }
}