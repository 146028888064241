.health-tip {
  max-width: 1200px;
  margin: 15px auto !important;
  display: grid;
  background-color: $secondary-color;
  border-radius: 25px;
  padding: 25px;
  grid-column-gap: 35px;
  grid-template: auto auto auto / 1fr;
  justify-items: center;
  grid-gap: 15px;
  @media screen and (min-width: 850px) {
    grid-template: 1fr / auto 1fr min-content;
  }
  .button {
    align-self: center;
  }
}