//aligns
.text--center {
  text-align: center;
}
.text--right {
  text-align: right;
}
// colors
.text--white {
  color: $white;
}
.text--dark-gray {
  color: $dark-gray;
}
.text--primary {
  color: $primary-color;
}
.text--secondary {
  color: $secondary-color;
}
.text--purple {
  color: $purple;
}
.text--green {
  color: $green;
}
.text--red {
  color: $red;
}
// line height
.line-height-one-one {
  line-height: 1.1;
}
// link hover colors
.hover--primary {
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    color: $primary-color;
  }
}
.hover--secondary {
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    color: $secondary-color;
  }
}
.hover--green {
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    color: $green;
  }
}
.hover--white {
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    color: $white;
  }
}
.hover--purple {
  transition: color .2s ease;
  &:hover, &:focus, &:active {
    color: $purple;
  }
}

// styles
.text--header {
  font-family: $header-font-family;
}
.text--body {
  font-family: $body-font-family;
}
.text--italic {
  font-style: italic;
}
// sizes
.font-26 {
  font-size: rem-calc(22);
  @include breakpoint(medium) {
    font-size: rem-calc(26);
  }
}
.font-36 {
  font-size: rem-calc(28);
  @include breakpoint(medium) {
    font-size: rem-calc(36);
  }
}
.font-42 {
  font-size: rem-calc(32);
  @include breakpoint(medium) {
    font-size: rem-calc(42);
  }
}

// transforms
.text--uppercase {
  text-transform: uppercase;
}
.text--lower {
  text-transform: lowercase;
}
.text--trans-none {
  text-transform: none;
}
// weights
.text--bold {
  font-weight: 700;
}
.text--900 {
  font-weight: 900;
}