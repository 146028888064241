// Margins
.no-margin {
  margin: 0;
}

.margin--top-0 {
  margin-top: 0 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-50 {
  margin-bottom: 50px !important;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-bottom-small-15 {
  @include breakpoint(small only) {
    margin-bottom: 15px;
  }
}

.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-left-m15 {
  margin-left: -15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-50 {
  margin-left: 50px;
}

// Float
.float-right {
  float: right;
}

.margin-bottom-15--mobile {
  @media screen and (max-width:1023px) {
    margin-bottom: 15px;
  }
}