html, body {
  overflow-x: hidden;
}
.container {
  max-width: 1500px;
  margin: 0 auto;
  @media screen and (max-width:1499px) {
    padding: 0 5%;
  }
}
.text-container {
  max-width: 1000px;
  margin: 0 auto;
  @media screen and (max-width:999px) {
    padding: 0 5%;
  }
}