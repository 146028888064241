// Padding
.no-padding-top {
  padding-top: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-top-0 {
  padding-top: 0 !important;
}
.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-20--medium {
  @include breakpoint(medium) {
    padding-left: 20px;
  }
  
}

.padding-top-bottom {
  padding: 7px 0;
}

section.section__padding--left-right {
  padding-left: 5%;
  padding-right: 5%;
}

.padding--left-right {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.padding--top-bottom {
  padding-top: 30px !important; 
  padding-bottom: 30px !important; 
}

.padding--top-bottom--large {
  padding-top: 80px !important; 
  padding-bottom: 80px !important; 
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-70 {
  padding-top: 70px;
}
.padding-top-100 {
  padding-top: 100px;
}

.padding-top-200 {
  padding-top: 200px !important;
}

.display--inline-block {
  display: inline-block;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-70 {
  padding-bottom: 70px;
}
.padding-bottom-100 {
  padding-bottom: 100px;
}

.half-section-padding {
  padding-left: 5%;
  padding-right: 5%;
  @include breakpoint(medium) {
    padding-left: 7%;
    padding-right: 7%;
  }
  @include breakpoint(large) {
    padding-left: 10%;
    padding-right: 10%;
  }
}