.background--primary {
  background-color: $primary-color;
}
.background--secondary {
  background-color: $secondary-color;
}
.background--purple {
  background-color: $purple;
}
.background--green {
  background-color: $green;
}
.background--dark-gray {
  background-color: $dark-gray;
}
.background--light-gray {
  background-color: $light-gray;
}
.background--red {
  background-color: $red;
}

// border
.border-right-yellow-large {
  @include breakpoint(large) {
    border-right: 3px solid $secondary-color;
  }
}
.border-right-purple-medium {
  @include breakpoint(medium) {
    border-right: 3px solid $primary-color;
    padding-right: 45px !important;
  }
}

// width
.full-width {
  width: 100%;
}