.button {
  font-size: rem-calc(23);
  transition: background-color .2s ease, color .2s ease;
  padding: 10px 50px;
  font-family: $header-font-family;
  border-radius: 15px;
}
.button--primary {
  background-color: $primary-color;
  &:hover, &:focus, &:active {
    outline: none;
    background-color: $secondary-color;
  }
}
.button--secondary {
  background-color: $secondary-color;
  color: $purple;
  &:hover, &:focus, &:active {
    outline: none;
    background-color: $purple;
    color: $secondary-color;
  }
}
.button--red {
  background-color: $secondary-color;
  color: $red;
  &:hover, &:focus, &:active {
    outline: none;
    background-color: lighten($secondary-color,20%);
    color: $red;
  }
}
.button--purple {
  background-color: $purple;
  color: $white;
  &:hover, &:focus, &:active {
    outline: none;
    background-color: $primary-color;
    color: $white;
  }
}