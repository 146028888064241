.service-card {
  padding: 35px;
  border-radius: 25px;
  img {
    float: right;
  }
}

.download-link {
  background: center center / contain no-repeat url('../img/yellow-circle.svg');
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // padding-top: 20px;
  h3 {
    font-size: rem-calc(25);
    max-width: 270px;
    margin: 10px auto;
    line-height: 1.1;
  }
}
.download-link__image {
  // margin-top: -35px;
  height: 130px;
}
.download-link__icon {
  height: 45px;
}